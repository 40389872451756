import React, { useEffect } from "react"
import { Typography } from "@material-ui/core"

import Layout from "./Layout"
import PageContainer from "./PageContainer"
import links from "../constants/links"

export default function RedirectPage() {
  const redirectToDownload = (): void => {
    window.location.href = links.appStore
  }

  useEffect(() => {
    redirectToDownload()
  }, [])

  return (
    <Layout>
      <PageContainer>
        <Typography variant="h1">Redirecting to app...</Typography>
      </PageContainer>
    </Layout>
  )
}
